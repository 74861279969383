@tailwind base;

.shell {
  max-width: 512px;
}

.chart-bg {
  background-image: url('./img/line.svg');
}

@tailwind components;
@tailwind utilities;
